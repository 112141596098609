import React, { useCallback, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Gallery, Layout, LightBox } from '../components'
import { TEXTS } from '../constants'

export default () => {
    const { galleries } = useStaticQuery(graphql`
      {
        galleries: allContentfulGallery(sort: {fields: index}) {
          nodes {
            id
            title
            images {
              id
              localFile {
                childImageSharp {
                  fluid(cropFocus: CENTER, fit: COVER, maxHeight: 300, maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
          }
        }
      }`)
    const [image, setImage] = useState()
    const handleOnOpen = useCallback((event) => {
        event.preventDefault()
        const { href } = event.currentTarget
        if (href) {
            setImage(href)
        }
    }, [])
    const handleOnClose = useCallback(() => setImage(void 0), [])
    return (
        <Layout header={TEXTS.gallery} pageTitle={TEXTS.gallery}>
            {galleries.nodes.map(({ id, ...props }) => (<Gallery key={id} onImageClick={handleOnOpen} {...props} />))}
            <LightBox image={image} onClose={handleOnClose}/>
        </Layout>
    )
}
